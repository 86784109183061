import React from "react"

import Layout from "../components/layout"

import { graphql } from "gatsby"
import Accordion from "../styles/Accordion"
import Credentials from "../components/credentials"
import SlickSlider from "../styles/SlickSlider"
import Footer from "../components/footer"
import Jobs from "../components/jobs"
import SEO from "../components/seo"

export default function CredentialsPage({ data }) {
  return (
    <Layout>
      <SEO title="Referenzen" />
      <Credentials>
        <h4>{ data.referenzen.edges[0].node.frontmatter.marker }</h4>
        <h2
          key={`h2`}
          id="___gatsby"
          dangerouslySetInnerHTML={{ __html: data.referenzen.edges[0].node.frontmatter.einstieg }}
        />
        {data.referenzen.edges[0].node.frontmatter.referenz.map((el, idx) => (
          <Accordion title={el.titel} key={idx} i={el}>
            { el.galerie_anzeigen &&  <SlickSlider>
              {
                el.galerie.map((element, index) => {
                  return <div key={idx + '-' + index}>
                      {/* <Image
                        fluid={
                          element.bild.childImageSharp
                            .fluid
                        }
                        imgStyle={{
                          objectFit: "cover",
                          objectPosition: "50% 50%",
                        }}
                      /> */}
                      <img style={{width: "100%", height: "100%", objectFit: "cover", objectPosition: "50% 50%"}} src={element.bild.publicURL} alt="energietechnik-schiefermayr"/>
                  </div>
                })
              }
            </SlickSlider> }
            <p
              dangerouslySetInnerHTML={{ __html: el.beschreibung }}
            />
          </Accordion>
        ))}
      </Credentials>
      <Jobs></Jobs>
      <Footer></Footer>
    </Layout>
  )
}

export const pageQuery = graphql`
  {
    referenzen: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/referenzen/"}}) {
      edges {
        node {
          frontmatter {
            marker
            einstieg
            referenz {
              titel
              beschreibung
              galerie_anzeigen
              galerie {
                bild {
                  absolutePath
                  publicURL
                  relativePath
                }
              }
            }
          }
        }
      }
    }
  }
`
