import React, { Component } from "react";
import Slider from "react-slick";
import { Helmet } from "react-helmet"
import styled from "styled-components";

const StyledSlider = styled(Slider)`
    .slick-list {
        width: calc(100% + 2.5rem);
        left: -1.25rem;
        margin-top: .5rem;
    }

    .slick-slide {
        padding: 0rem 1.25rem;
        position: relative;
    }

    .gatsby-image-wrapper {
      height: 0;
      padding-bottom: 25rem;
      @media (max-width: ${({ theme }) => theme.pageWidth}) {
        padding-bottom: 20rem;
      }
      @media (max-width: 780px) {
        padding-bottom: 15rem;
      }
    }

    .slick-arrow {
      display: none!important;
    }
`

const StyledArrow = styled.button`
        min-width: 170px;
        text-align: center;
        position: relative;
        z-index: 10;
        font-size: 1.2rem;
        padding: 1rem;
        border-radius: 50px;
        border: none;
        outline: none;
        cursor: pointer;
        color: ${(props) => props.theme.colors.secondary};
        background-color: ${(props) => props.theme.colors.primary};
        margin-bottom: 2.5rem;

        @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
          min-width: 150px;
          font-size: 1rem;
          padding: .9rem;
          margin-bottom: 2rem;
        }
`

export default class SlickSlider extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }

  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }

  render() {
    const settings = {
      dots: false,
      arrows: false,
      infinite: false,
      focusOnSelect: false,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 780,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    };
    return (
      <div>
        <Helmet>
          <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
        </Helmet>
        <StyledSlider ref={c => (this.slider = c)} {...settings}>
            {this.props.children}
        </StyledSlider>
        <div css={`
          margin-top: 2.5rem;
          display: flex;
          justify-content: center;
          align-items: center;

          @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
            margin-top: 1.5rem;
          }
        `}>
          <div css={`
            width: 50%;
          `}>
            <StyledArrow onClick={this.previous}>
              Voriges Bild
            </StyledArrow>
          </div>
          <div css={`
            width: 50%;
            text-align: right;
          `}>
            <StyledArrow onClick={this.next}>
              Nächstes Bild
            </StyledArrow>
          </div>
        </div>
      </div>
    );
  }
}